<template>
    <!-- <div :id="id" :class="className" :style="{height:height,width:width}" /> -->
    <v-chart :option="option" />
</template>
    
<script>
// import ECharts from 'vue-echarts';
// import "echarts/lib/chart/line";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);
// import resize from './mixins/resize'
export default {
    components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "dark"
  },
    // mixins: [resize],
    // props: {
    //     className: {
    //         type: String,
    //         default: 'chart'
    //     },
    //     id: {
    //         type: String,
    //         default: 'chart'
    //     },
    //     width: {
    //         type: String,
    //         default: '100%'
    //     },
    //     height: {
    //         type: String,
    //         default: '100%'
    //     },
    //     title: {
    //         type: String
    //     },
    //     data: {
    //         type: Array
    //     }
    // },
    data() {
        return {
            chart: null,
            option: {},
        }
    },
    mounted() {
        this.initChart()
    },
    beforeDestroy() {
    },
    methods: {
        initChart() {
            this.option = {
                title: {
                    text: 'Temperature Change in the Coming Week'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {},
                toolbox: {
                    show: true,
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        dataView: { readOnly: false },
                        magicType: { type: ['line', 'bar'] },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} °C'
                    }
                },
                series: [
                    {
                        name: 'Highest',
                        type: 'line',
                        data: [10, 11, 13, 11, 12, 12, 9],
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        }
                    },
                    {
                        name: 'Lowest',
                        type: 'line',
                        data: [1, -2, 2, 5, 3, 2, 0],
                        markPoint: {
                            data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
                        },
                        markLine: {
                            data: [
                                { type: 'average', name: 'Avg' },
                                [
                                    {
                                        symbol: 'none',
                                        x: '90%',
                                        yAxis: 'max'
                                    },
                                    {
                                        symbol: 'circle',
                                        label: {
                                            position: 'start',
                                            formatter: 'Max'
                                        },
                                        type: 'max',
                                        name: '最高点'
                                    }
                                ]
                            ]
                        }
                    }
                ]
            }
        }
    }
}
</script>